import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IonButton, IonContent, IonPage } from '@ionic/react';

import Footer from '@/components/molecules/Footer.tsx';
import { PageMeta } from '@/contexts/PageMetaContext.tsx';

export default function NotFoundPage() {
  const { t } = useTranslation();

  useEffect(() => {
    const element = document.querySelector('ion-tab-bar');
    if (element) {
      element.style.display = 'none';
    }

    return () => {
      if (element) {
        element.style.display = '';
      }
    };
  }, []);

  return (
    <IonPage>
      <PageMeta title={t('messages.pageNotFound')} />

      <IonContent>
        <div className="flex min-h-full flex-col gap-6 lg:gap-12">
          <div className="my-auto flex h-full w-full items-center justify-center">
            <IonButton href="/">{t('messages.pageNotFound')}</IonButton>
          </div>
          <Footer />
        </div>
      </IonContent>
    </IonPage>
  );
}
